.wrapper_cards {
    max-width: 1232px;
    margin: auto;
    padding: 46px 0;
    /* padding: 46px 17.8%;  */
    /* 342px / 1921px = 17.8% */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 46px;
}

.wrapper_cards_row {
    display: flex;
    flex-direction: column;
    gap: 46px;
}

.cards_row_cards {
    display: flex;
    flex-direction: column;
    gap: 47px;
}

.card_row_wrap {
    position: relative;
}

.card_row {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    align-items: center;
    background-color: #F5F7F9;
    /* background-color: #FAFEF7; */
    padding: 12px 24px;
    filter: drop-shadow(9px 5px 20px rgba(1, 51, 0, 0.06));
    box-shadow: 2px 2px 22px rgba(40, 40, 40, 0.12);
    border-radius: 23px;
    z-index: 10;
}

.card_row_notification {
    position: absolute;
    min-width: 265px;
    height: 51px; 
    left: 0px;
    top: -28px;
    background-image: url('../assets/img/notification-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* background: linear-gradient(91.9deg, #22284B -5.77%, #1F2F65 24.99%, #3FBBB3 98.81%); */
    border-radius: 7px;
    z-index: 5;
}

.card_row_notification p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16.44px;
    color: #F5F7F9;
    margin: 0 40px;
    padding-top: 6px;
    text-align: left;
    margin-left: 14px;
    white-space: nowrap;
    letter-spacing: 0.02em;
}

.card_bank_row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.card_bank_table {
    display: grid;
    grid-template-columns: 70px auto auto;
    grid-template-areas: 
    "A N N "
    "A B B"
    "A C C"
    "A NN NN";
    row-gap: 0;
    column-gap: 0;
    align-items: center;
}

/* .card_bank_row {
    margin-right: 14.794%;
    /* margin-right: 183px; 
} */

.card_bank_img {
    grid-area: A;
    height: 60px;
    width: 60px;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;

}

.card_bank_row .card_bank_title {
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: flex-start;
}

.card_bank_title {   
    display: flex;
    grid-area: B;
    /* flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: flex-start; */
}

.card_bank_p {
    grid-area: C;
}

.percent_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sum, .percent, .time, .age {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: flex-start;
}

.card_description {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 3%;
    margin-left: 24.72%;
    margin-right: 11.8872%;
    /* margin-left: 183px;
    margin-right: 88px; */
}

.sum h5, .percent h5, .time h5, .age h5 {
    margin: 0;
    color: #82887D;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
}

.sum p, .percent p, .time p, .age p {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 18.78px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #453F53;
    white-space: nowrap;
    letter-spacing: 0;
}

.card_bank_title h5 {
    margin: 0;
    font-weight: 600;
    /* font-size: 20px; */
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #0C0C0C;
}

.card_bank_p p, .card_bank_row .card_bank_title p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    /* font-size: 10px;
    line-height: 12px; */
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #82887D;
    text-align: left;
}

.card_button_container {
    display: flex;
    background-color: #161616;
    border-radius: 16px;
    height: 61px;
    position: relative;
    overflow: hidden;
}

/* .card_button_container_row {
    margin-left: 88px;
} */

.card_button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 11px 0 21px;
}

.card_button_container_row .card_button {
    gap: 20px;
}

  /* .card_button_container:nth-child(1) {
    background: cornflowerblue;
  }
  .card_button_container:nth-child(2) {
    background: salmon;
  }
  .card_button_container:nth-child(3) {
    background: gray;
  } */
  /**
    * The "shine" element
    */
  
  /* .card_button_container:after {
    content: "";
    height: 40%;
    left: -230%;
    opacity: 0;
    position: absolute;
    top: -20%;
    transform: rotate(-30deg);
    width: 200%;
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
  } */
  /* Hover state - trigger effect */
/*   
  .card_button_container:hover:after {
    left: 50%;
    opacity: 1;
    top: -50%;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-property: left, top, opacity;
    transition-timing-function: ease;
  } */


.card_button_container:hover {
    cursor: pointer;
    background-color: #454545;
    transition: background-color .5s;
}

.card_button_container:hover:after {
    content: "";
    display: block;
    width: 10px;
    height: 200px;
    margin-left: -50px;
    background: #fff;
    background: linear-gradient(
        to bottom,
        #22284B 0%,
        #FFB026 50%,
        #3FBBB3 100%
    );
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 2.5s ease-in-out 0.05s infinite;
    animation-iteration-count: infinite;
  }
   
  @keyframes movingFlare {
    0% {
      left: -30px;
      margin-left: 0px;
    }
    30% {
      left: 110%;
      margin-left: 80px;
    }
    100% {
      left: 110%;
      margin-left: 80px;
    }
  }

.card_button p {
    margin: 0;
    font-family: 'Unbounded';
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
}

.card_button_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    background: linear-gradient(135deg, #FFB026 0.61%, #FF7B0E 100.61%);
    border-radius: 10px;
}

.card_button_link {
    text-decoration: none;
}

.wrapper_cards_table {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.card_table_wrap {
    width: 100%;
    /* margin-right: 24px;
    width: 25%; */
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #F5F7F9;
    /* background-color: #FAFEF7; */
    box-shadow: 2px 2px 22px rgba(40, 40, 40, 0.12);
    /* filter: drop-shadow(9px 5px 20px rgba(1, 51, 0, 0.06)); */
    border-radius: 23px;
}

.cards_table_cards {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;

    /* display: flex;
    flex-direction: row;
    margin-right: -24px;
    /* gap: 24px; 
    flex-wrap: no-wrap; */
}

.card_table {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding: 0 16px 24px;
    
}

.card_description_table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
    grid-template-areas: 
    "A B"
    "C D";
}

.card_description_table .percent {
    grid-area: C;
}

.card_table_notification {
    width: max-content;
    height: 32px; 
    background: linear-gradient(91.9deg, #22284B -5.77%, #1F2F65 24.99%, #3FBBB3 98.81%), linear-gradient(91.9deg, #11BA55 -5.77%, #96D617 59.66%, #FFEA28 101.61%);
    /* background: linear-gradient(91.9deg, #22284B -5.77%, #1F2F65 24.99%, #3FBBB3 98.81%); */
    border-radius: 23px 0 7px 0;
}

.card_table_notification p {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #F5F7F9;
    margin: 5px 20px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: 0;
}

.card_table_wrap .ant-card-body {
    padding: 0 !important;
}

#table_from_row {
    display: none;
}

@media screen and (max-width: 1279px) {

    #table_from_row {
        display: grid;
    }

    .cards_row_cards {
        display: none;
    }

    .wrapper_cards {
        max-width: 704px;
    }

    .cards_table_cards {
        grid-template-columns: 1fr 1fr ;
        /* justify-content: space-between;
        flex-wrap: wrap; */
    }

}

@media screen and (max-width: 767px) and (min-width: 375px) {

    .wrapper_cards {
        padding: 24px 0;
        gap: 24px;
    }

    .wrapper_cards_row, .wrapper_cards_table {
        gap: 20px;
    }

    .cards_table_cards {
        grid-template-columns: 1fr 1fr;
        column-gap: 7px;
        row-gap: 20px;
    }

    .card_table_wrap {
        min-width: 168px;
        gap: 8px;
    }
    
    .card_table {
        padding: 0 8px;
        gap: 14px;
    }

    .card_bank {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-areas: 
        "A B B"
        "C C C";
        row-gap: 4px;
        column-gap: 0;
    }

    .card_bank_img {
        grid-area: A;
        align-self: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
    }

    .card_bank_img img {
        width: 32px;
        height: 32px;
    }

    .card_bank_title{
        grid-area: B;
    }

    .card_bank_p {
        grid-area: C;
    }

    .card_bank_p p {
        font-size: 10px;
    }

    .card_description_table { 
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }

    .percent, .time, .age {
        flex-direction: row;
        align-items: center;
        gap: 7px;
    }

    .sum p {
        font-size: 12px;
        line-height: 14px;
    }

    .percent p, .time p, .age p {
        font-size: 12px;
        line-height: 12px;
    }

    .card_button_container {
        height: 35px;
    }

    .card_button {
        margin: auto;
        justify-content: center;
    }

    .card_button p {
        font-size: 12px;
        line-height: 13px;
    }

    .card_button_arrow {
        display: none;
    }

    .card_table_notification {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 42px;
        width: auto;
        border-radius: 16px 16px 0 0;
    }

    .card_table_notification p {
        white-space: normal;
        text-align: left;
        font-size: 13px;
        line-height: 16px;
    } 

    .wrapper_cards {
        max-width: 344px;
    }
}

@media screen and (max-width: 374px) {

    .cards_table_cards {
        grid-template-columns: 1fr;
    }
}