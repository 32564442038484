.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text_title {
  font-family: 'Unbounded', sans-serif;
  font-size: 40px;
  line-height: 52px;
  font-weight: 500;
  margin: 0;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 64px 0 114px 0;
  max-width: 1232px;
  margin: auto;
  /* padding: 64px 344px 114px 340px; */
}

.footer_text p {
  text-align: left;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6A6E7E;

}


@media screen and (max-width: 1280px) {

  .text_title {
    font-size: 24px;
    line-height: 130%;
  }

  .footer {
    max-width: 704px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    max-width: 344px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 375px) {
  .footer {
    width: 90%;
  }

  .text_title {
    font-size: 16px;
    line-height: 20.8px;
  }
}