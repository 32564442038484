.header__first-row {
    max-width: 1576px;
    margin: auto;
}

.header__first-row_container {
    max-width: 1557px;
    margin: auto;
    padding: 16px 0;
    /* padding: 16px 9.4742% 16px 9.37%;  */
    /* 16px 182px 16px 180px */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header__logo {
    height: 39px;
}

.header__location_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    display: none;
}

.header__first-row_container p {
    margin: 0;
    color: #0A0E20;
}

.header__location {
    display: flex;
    align-items: center;
}

.header__second-row_container {
    background-image: url('../assets/img/header_background.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header__second-row {
    /* position: relative; */
    /* padding: 0 17.8% 0 17.647%;  */
    /* 61px 342px 59px 339px */
    max-width: 1232px;
    margin: auto; 
    display: flex;
    flex-direction: row;
    gap: 29px;
    align-items: center;
}

.header__second-row_image {
    display: flex;
    align-self: flex-end;
}

/* .header_circle {
    position: absolute;
    width: 510px;
    height: 510px;
    left: 1067px;
    top: 62px;
    overflow: hidden;
    border-radius: 255px;
    background: linear-gradient(238.14deg, #B7EBC9 14.3%, #E6F798 85.34%);
} */

.header__second-row_text_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    text-align: left;
    /*padding: 61px 17.8% 59px 17.647%;  61px 342px 59px 339px */ 
}

.header__second-row_text_table {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.header__second-row_text_table_element {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.header__second-row_text_table_element_bolt {
    display: flex;
    align-items: center;
}

.header__second-row_text_table_element p {
    margin: 0;
    line-height: 19px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

#banner768 {
    display: none;
}

@media screen and (max-width: 1576px) {
    .header__first-row {
        max-width: 1232px;
        margin: auto;
    }
}

@media screen and (max-width: 1280px) {

    #banner1920 {
        display: none;
    }

    #banner768 {
        display: block;
    }

    .header__first-row {
        max-width: 704px;
    }

    .header__second-row {
        max-width: 704px;
    }

    .header__second-row_text_container {
        padding: 42px 0 32px 0 ;
    }
}

@media screen and (max-width: 768px) {
    #banner768 {
        display: none;
    }

    .header__first-row, .header__second-row {
        max-width: 344px;
    }

    .header__first-row_container {
        max-width: 344px;
        justify-content: center;
    }
}

@media screen and (max-width: 375px) {
    .header__first-row, .header__second-row {
        max-width: 90%;
    }

    .header__second-row_container h1 {
        font-size: 22px;
        line-height: 28.6px;
    }

    .wrapper_cards {
        width: 90%;
    }
}